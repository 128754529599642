
body {
  background: #ffffff;
}
  .card {
    background: rgb(143, 56, 56);
    border-radius: 14px;
    box-shadow: 0px 14px 80px rgba(50, 50, 55, 0.5);
    max-width: 400px;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
  }
  
  @import "react-slideshow-image/dist/styles.css";

  * {box-sizing: border-box;}
body {font-family: Verdana, sans-serif;}
.mySlides {display: none;}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {font-size: 11px}
}

.a{
  background-color: #009dff;
}
.section-status .img-container{
  text-align: center;
  padding: 1rem 0;
}
.section-status .img-container img{
  height: 5rem;
}			
.section-status .text{
  text-align: center;
}

.card {
  box-shadow: 0 0 15px rgb(0 0 0 / 7%);
  margin: 1rem 0;
  border-radius: 2rem;
}

.banner_img{
  width: 100%;
}
.card_img_s{
  width: 75%;
}
.font_s{
  font-weight: 600;
}


.grid-container {
  display: grid;
  column-gap: auto;
  grid-template-columns: auto auto auto;
  border: 1px solid #a3aaf9bc;
  padding: 5px;
}

.grid-itemm {
  
  font-size: 15px;
  text-align: center;
}