
  
  .cardv {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
    border-radius: 4%;
  }

  .containerv {
    height: 100%;   
    background-size: cover;
    background-position: 50% 0%;
    overflow: hidden;
    padding: 0 16px;
    margin-left: 14%;
}
  
   
  .title {
    color: grey;
   
  }
  
  .button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
  }
  
  .button:hover {
    background-color: #555;
  }
  
  @media screen and (max-width: 650px) {
    .column {
      width: 100%;
      display: block;
    }
  }

  .slogo{   
   
    font-size: 2rem;
    margin-top: 25px;
    margin-left: 20%;
      }

.card_color{
    background-color: rgba(148, 175, 217, 0.5);
    margin-top: 1%;   
    padding: 10px;
    border-radius: 8px;
    margin: 3%;
}

.c{
  color: #0008ff;
}
.c1{
  color: #2b6ac2;
}

.facebook{
  color: #0008ff;
}

.insta{
  color: rgb(192, 38, 38);
}
.clogo{     
    
    margin-top: 5px;
    margin-right: 13%;
    margin: 0%;
}

.img-top{
    margin-top: 0%;
    background-color: #f5f5f500;
    width: 100%;
    margin-top: 0%;
    border-radius: 8px;
}
.card-img-top {
  height: 100%;
  object-fit: cover;
}

.si{
    margin-top: 0%;
    background-color: #f5f5f500;
    width: 100%;
    margin-top: 0%;
    border-radius: 8px;
    height: 100%; 
    object-fit: contain;
}



.fs{
  margin-left: 13%;
}

.profile_image{
  height: 210px;
  width: 126px;
}
.center_g {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.title1{
  font-weight: 700;
}