 .card1 {
    background: rgb(255, 255, 255);
    border-radius: 14px;
    box-shadow: 0px 14px 80px rgba(50, 50, 55, 0.5);
   padding: 3%;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    position: relative;
  }

  .font_s{
    font-family: Poppins;
  }