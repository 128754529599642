.header {
    text-align: left;
    background:transparent;
  }
  
  .lef{
    background-color: #009dff;
   }

  .a{   
    height: 90px;
  }

  footer {
  text-align: center;
  padding: 3px;
  background-color: DarkSalmon;
  color: white;
}

.home{
  width: 10%;
}

.logo{
 width: 100px;
 margin-left: 5%;
 margin-top: 2%;
 margin-bottom: 2%;
}

.l{
width: 45%;

}

.header_icon{
  float: right;
  color:white;
  font-size: 1.5rem;
  margin-top: 25px;
  margin-right: 10px;
}

.bell{
  width: 2.7rem;
  margin-top: 25px;
  margin-right: 10px;
  float: right;
}

.szh-menu__item{
  cursor:pointer;
  padding: 10px;
}


a {
  color: inherit;
  text-decoration: none;}