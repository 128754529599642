.cardne{
    padding: 1%;
   
}
.imn{
    width: 100%;
   
}

.font-f{
    font-family: initial;
}