* {
  box-sizing: border-box;
}

.column {
  margin-top: 1%;
  float: left;
  width: 50%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}

.three-column-images
{
  background:white;
}

.three-column-images .row
{
  text-align:center;
}
.three-column-images img
{
  margin-bottom:10px;
 
}

.three-column-images a
{
  font-weight:bold;
}

.font_title{
  font-size: 70%;
  font-weight: bold;
}


/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
      width: 100%;
  }
}
.out {
  animation-name: zoom-out;
  animation-duration: 0.6s;
}
/* Add Animation */
.modal-content, #caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
@-webkit-keyframes zoom-out {
  from {transform:scale(1)}
  to {transform:scale(0)}
}
@keyframes zoom-out {
  from {transform:scale(1)}
  to {transform:scale(0)}
}
@-webkit-keyframes zoom {
  from {-webkit-transform:scale(1)}
  to {-webkit-transform:scale(2)}
}

@keyframes zoom {
  from {transform:scale(0.4)}
  to {transform:scale(1)}
}
/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}
.modal-content {
    margin: auto;
    display: block;
    width: 75%;
    max-width: 75%;
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}
#myImg:hover {opacity: 0.7;}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  display: block;
  margin-left: auto;
  margin-right: auto
}
.card_g {
  box-shadow: 0 0 15px rgb(0 0 0 / 7%);
  margin: 0rem 0;
 
}

.card-img-top {
    height: 200px;
    object-fit: cover;
}