.profile-boxs {
    background: #FFFFFF;
    box-shadow: 0 2px 6px 0 rgb(47 83 151 / 10%);
    border-radius: 23px;
    margin: 10px 10px;
    padding: 16px 16px;
    text-align: center;
    transition: 300ms;
    flex-grow: 1;
}
.col-md-6l {
    flex: 0 0 auto;
    width: 45%;
}
.ffc{
    font-weight: 600;
    font-size: 91%;
    margin-top: 9%;
}
.imageslider{
    width: 98%;
    
}
