@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

.page-wrapper {
  font-family: 'Lato', sans-serif;
  min-height: 100vh;
  background-color: #F5F7FA;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  padding-left: calc(5% - 16px);
  padding-right: calc(5% - 16px);
  /* padding: 0 5%; */
}

nav {
  min-height: 72px;
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.05);
  padding: 0 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-box {
  background: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(47,83,151,0.10);
  border-radius: 23px;
  margin: 10px 10px;
  padding: 16px 16px;
  width: 300px;
  text-align: center;
  transition: 300ms;
  flex-grow: 1;
}

.profile-box:hover {
  /* box-shadow: 0 2px 6px 0 rgba(47,83,151,0.40); */
  box-shadow: 0 0 20px 9px rgba(0,0,0,0.03);
}

.img_G{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(47,83,151,0.10);
}

#logo {
  width: 127px;
  height: 100%;
  border-radius: 0%;
  background-color: white;
  margin-right: 32px;
  box-shadow: none;
}

#searchField {
  width: 100%;
  background: rgba(245,247,250,0.20);
  border: 1px solid #F5F7FA;
  border-radius: 6px;
  padding: 8px 16px;
}

::placeholder {
  color: #98A0A6;
  font-family: 'Lato', sans-serif;
  font-style: italic;
  font-weight: 300;
}

h3 {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 0;
}

h4 {
  transition: 500ms;
  font-weight: 300;
  font-size: 14px;
  margin-top: 8px;
  color: #98A0A6;
  cursor: pointer;
}

h4:hover {
  color: #507BFC;
  text-decoration: underline;
}

#assign,
#view {
  width: 50%;
  font-size: 14px;
  color: #98A0A6;
  font-weight: 300;
  padding: 8px 0;
  border-radius: 100px;
  transition: 300ms;
  cursor: pointer;
}

#assign:hover,
#view:hover {
  background-color: #507BFC;
  color: white;
  box-shadow: 0 10px 20px 0 rgba(80,123,252,0.50);
}

#assign {
  float: left;
}



i {
  margin-right: 8px;
}

.ff1{
  font-weight: 600;
  font-size: 133%;
  margin-top: 3%;
}
.ff2{
  font-size: 100%;
}

.f11 {
  font-size: 130%;
  font-weight: 600;
  color:#008037;
}
.link_a{
  color: rgb(3, 4, 4);
    text-decoration: none;
}