.imgG{
    width: 37%;
    padding: 9px;

}
.page-wrappers {
    
    margin: auto;
    align-content: flex-start;
    justify-content: space-evenly;
    padding-left: calc(5% - 16px);
    padding-right: calc(5% - 16px);
   padding: 0 5%; 
  }

.card-title {
    color: #a90f0f;
    font-size: 89%;
    font-weight: 600;
    text-align: start;
}

.age{
    margin-right: 46%;
}

.pro{
   
    box-shadow: 0 2px 6px 0 rgb(47 83 151 / 10%);
    border-radius: 23px;
   
}
.sm{
    margin-right: 64%;
}
.ic{
    
    font-size: 1.5rem;
    color: rgb(255, 16, 92);
}
.ic1{
   margin: 4%;
   text-align: left;
   color: #104356;
   
    margin-left: -1%;
}

.ic2{
  
    margin: 4%;
    text-align-last: start;
    color: #104356;
    font-size: 100%;
    margin-left: -1%; 
    color: red;
}

.ic3{
      color: #104356;   
}

.sakha{
    font-weight: 700;
    margin-top: 5%;
    color: #004aad;
}
.icon_size{
    font-weight: 500;
    color: #230909;
    font-size: 55%;
    margin-left: 6%;
}

.card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: #fff;
    --bs-card-border-radius: 0.375rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(0.375rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
   
   
}
