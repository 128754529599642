@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif !important;
}
a:hover {
    text-decoration: none;
}
.rounded-circles {
  border-radius: 50%!important;
  margin-left: 4%;
}

.p{
  font-size: 59%;
    font-weight: 500;
    font-style: bold;
    margin-top: 5%;
}

.text-secondary{
  margin-left: 6%;

}

.img_aa{
  width: 50%;
    height: 51%;
    margin-left: 20%;
}

.f1{
  font-size: 50%;
}


.eventcard {
  background: rgb(255, 255, 255);
  border: 1px solid #dd2476;
  color: rgba(250, 250, 250, 0.8);
  margin-bottom: 2rem;
}

.eventbtn {
  border: 5px solid;
  border-image-slice: 1;
  background: var(--gradient) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  border-image-source:  var(--gradient) !important; 
  text-decoration: none;
  transition: all .4s ease;
  margin:3px;
  padding:3px;
}

.eventbtnbtn:hover, .eventbtnbtn:focus {
      background: var(--gradient) !important;
  -webkit-background-clip: none !important;
  -webkit-text-fill-color: #fff !important;
  border: 5px solid #fff !important; 
  box-shadow: #222 1px 0 10px;
  text-decoration: underline;
}

:root {
  --gradient: linear-gradient(to left top, #DD2476 10%, #FF512F 90%) !important;
}

.card-body{
  padding:10px;
}

.card-img-top {
  height: 200px;
  
  object-fit: cover;
}

.abc{
  position: relative;
  font-weight: 500px;
  color: rgb(0, 0, 0);
  line-height: 1.55;
  font-size: 80%;
}

.read-or-hide {
  color: rgb(174, 163, 236);
}
.card-subtitles {
  color: #ffffff;
  font-size: 65%;
}
.clock_icon1{
  font-size: 10px;
  color: rgb(255, 255, 255);
}

* {box-sizing: border-box}

/* Set height of body and the document to 100% */
body, html {
  height: 100%;
  margin: 0;
  font-family: Arial;
}

/* Style tab links */
.tablink {
  background-color:#0d0f3c;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 0px;
  font-size: 17px;
  width: 25%;
}

.tablink:hover {
  background-color: #777;
  
}

/* Style the tab content (and add height:100% for full page content) */
.tabcontent {
  color: white;
  display: none;
  padding: 100px 0px;
  height: 100%;
}

#Home {background-color: red;}
#News {background-color: green;}
#Contact {background-color: blue;}
#About {background-color: orange;}