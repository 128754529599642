

.page-wrappers {
    font-family: 'Lato', sans-serif;
    min-height: 100vh;
    background-color: #F5F7FA;
    margin: auto;
   
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-evenly;
    padding-left: calc(5% - 16px);
    padding-right: calc(5% - 16px);
    /* padding: 0 5%; */
  }

  .dropdown-menu li {
    position: relative;
    }
    .dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
    }
    .dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
    }
    .dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
    }

    .popup-wrapper {
      width: 100%;
    }
    .popup-wrapper > * {
      width: 50%;
    }

    .full_size{
      width:100%;
      margin: 5px;
    }


    .modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.popup-content {
  margin: 10px;
  background: #fff;
  width: -webkit-fill-available;
  border: 1px solid #d7d7d7;
  padding: 10px;
}

    
  
    
  